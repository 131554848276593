import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./styles/navbar.module.scss";
import { useTranslation } from "react-i18next";
import { useAuth, useUser } from "../AuthContext";
import ListGroups from "./ListGroups";
import { ThemeType } from "./TopBar";
import useOutsideClick from "../UseOutsideClick";
import { UserProfile } from "..";

export default function AccountButton({
  backendUrl,
  //handleUpdateUserTheme,
  theme,
  connectUrl,
}: {
  backendUrl?: string;
  theme?: ThemeType;
  handleUpdateUserTheme?: (theme: ThemeType) => Promise<void>;
  pageName?: string;
  connectUrl?: string;
}) {
  const [showLogout, setLogoutVisible] = useState(false);
  const toggleLogoutVisibility = useCallback(
    () => setLogoutVisible((visible) => !visible),
    []
  );

  const { auth } = useAuth();
  const handleLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const { t } = useTranslation();

  const refDivInside = useRef<HTMLDivElement>(null);

  useOutsideClick(refDivInside, () => {
    showLogout && toggleLogoutVisibility();
  });
  const user = useUser();

  useEffect(() => {
    async function setProfile(user: UserProfile) {
      const backendUrl = process.env["REACT_APP_BACKEND_URL"];
      const url = new URL(`${backendUrl}/user/configure-user`).toString();

      const init: RequestInit = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          userCompany: user.company ?? "My Company",
          userId: user.id,
        }),
      };
      await fetch(url, init);
      window.location.reload();
    }
    if (user && !user.defaultTeam) {
      setProfile(user);
    }
  }, [user]);

  const username =
    typeof user === "object"
      ? `${user?.firstName} ${user?.lastName}`
      : undefined;

  const initials =
    typeof user === "object"
      ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
      : undefined;

  const teams = typeof user === "object" ? user.teams : undefined;

  const defaultTeam = typeof user === "object" ? user.defaultTeam : undefined;

  return (
    <>
      <button
        className={classNames(styles.navbar_btnlogin, styles.btn)}
        onClick={toggleLogoutVisibility}
      >
        <div
          className={classNames(
            process.env.REACT_APP_IS_STAGING
              ? styles.compteStaging
              : styles.compte
          )}
        >
          <p className={classNames(styles.letter)}>{initials}</p>
        </div>
      </button>
      {showLogout && (
        <div
          className={classNames(styles.option_log, "row")}
          ref={refDivInside}
        >
          <div>
            <div
              className={classNames(
                process.env.REACT_APP_IS_STAGING
                  ? styles.compteStaging
                  : styles.compte
              )}
            >
              <p className={classNames(styles.letter)}>{initials}</p>
            </div>
          </div>
          <div className={classNames(styles.userinfo, "col-12")}>
            <>
              <p className={classNames(styles.nameuser, styles.name)}>
                {username}
              </p>

              {teams && defaultTeam ? (
                <ListGroups
                  backendUrl={backendUrl}
                  defaultValue={defaultTeam}
                  teams={teams}
                />
              ) : (
                ""
              )}
            </>
          </div>

          {connectUrl && (
            <div className={classNames(styles.themecolor)}>
              <label htmlFor="darkTheme">
                <a href={`${connectUrl}profil`} target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    fill={theme === "dark" ? "white" : "black"}
                  >
                    <g>
                      <path d="M0,0h24v24H0V0z" fill="none" />
                      <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                    </g>
                  </svg>
                </a>
              </label>
            </div>
          )}

          {/* {pageName === "Connect" && (
            <div className={classNames(styles.themecolor)}>
              <label htmlFor="darkTheme">
                {theme === "dark" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#ffffff"
                  >
                    <rect fill="none" height="24" width="24" />
                    <path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <rect fill="none" height="24" width="24" />
                    <path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
                  </svg>
                )}
              </label>
              <input
                className={classNames(styles.displaynone)}
                type="checkbox"
                name="darkTheme"
                id="darkTheme"
                checked={theme === "dark"}
                onChange={(e) => {
                  if (handleUpdateUserTheme) {
                    handleUpdateUserTheme(e.target.checked ? "dark" : "light");
                  }
                }}
              />
            </div>
          )} */}

          <p
            className={classNames(styles.logout, "col-8")}
            onClick={handleLogout}
          >
            {t("Logout")}
          </p>
        </div>
      )}
    </>
  );
}
