export const translate = {
  en: {
    translation: {
      //Account
      Logout: "Logout",
      createaccount: "Log in / Register",

      //Navbar
      Home: "Home",
      My_Profile: "My Profile",
    },
  },

  fr: {
    translation: {
      //Account
      Logout: "Se Déconnecter",
      createaccount: "Connexion / Créer un compte",

      //Navbar
      Home: "Accueil",
      My_Profile: "Mon Profil",
    },
  },

  ja: {
    translation: {},
  },
};
